import dynamic from 'next/dynamic';
import type { GlobalSFSCChat } from '~/amplienceTypes/schemas/imported/global-sfsc-chat-schema';
import { useConsentContext } from '~/utilities/context/dynamic/ConsentContext';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import { ChatAgent } from '~/utilities/graphql/codegen';

const SfscChat = dynamic(async () => (await import('../sfscChat/SfscChat')).SfscChat, {
  // SfscChat component is designed to work client-side only, it breaks the build if ssr is enabled
  ssr: false,
});

const ZowieChat = dynamic(async () => (await import('../vendors/Zowie')).ZowieChat, {
  ssr: true,
});

type ChatSupportProps = {
  sfscChatContent?: GlobalSFSCChat;
};

export const ChatSupport = ({ sfscChatContent }: ChatSupportProps) => {
  const {
    configuration: { chatAgent },
  } = useStaticContext();
  const { hasMarketingConsent, hasAnalyticsConsent } = useConsentContext();

  if (!chatAgent) {
    return <></>;
  }

  if (
    chatAgent === ChatAgent.Salesforce &&
    sfscChatContent &&
    hasMarketingConsent &&
    hasAnalyticsConsent
  ) {
    return <SfscChat {...sfscChatContent} />;
  }

  if (chatAgent === ChatAgent.Zowie && hasAnalyticsConsent) {
    return <ZowieChat />;
  }

  return <></>;
};
